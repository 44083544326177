
import { Vue, Options } from "vue-class-component";
import CardDashboardClient from "@/app/ui/views/dashboard/component/client/component/card-dashboard-client.vue";
import DetailStatusCardDashboardClient from "@/app/ui/views/dashboard/component/client/component/detail-status-card-dashboard-client.vue";
import BulletPoint from "@/app/ui/components/icons/modules/bullet-point.vue";
import ProgressBar from "@/app/ui/components/progress-bar/index.vue";
import { DashboardController } from "@/app/ui/controllers/DashboardController";
import { DataCorporateDashboard } from "@/domain/entities/Dashboard";
import { IS_SENDER_ACCOUNT } from "@/app/infrastructures/misc/Utils";
import checkRolePermission from "@/app/infrastructures/misc/common-library/CheckRolePermission";
import { TRACK_DELIVERY } from "@/app/infrastructures/misc/RolePermission";

@Options({
  components: {
    BulletPoint,
    DetailStatusCardDashboardClient,
    CardDashboardClient,
    ProgressBar
  }
})
export default class DeliveryStatusCard extends Vue {
  async mounted() {
    await this.initCorporateDashboardTotalDelivery();
    await this.initCorporateDashboardDeliveryPerStatus();
  }

  async initCorporateDashboardTotalDelivery() {
    this.totalStatusDelivery.loading = true;
    const response = await DashboardController.getCorporateDashboardDeliveryData(
      {
        path: "/delivery/total",
        sttCategory: "",
        cache: true
      }
    );
    this.totalStatusDelivery.loading = false;
    this.totalStatusDelivery.value = response.totalStt;
  }
  async initCorporateDashboardDeliveryPerStatus() {
    let delay = 0;
    const delayIncrement = 250;
    const requestCorporateDashboardDelivery = this.deliveryPerStatus.map(
      (delivery: any) => {
        delivery.loading = true;
        delay += delayIncrement;
        return new Promise(resolve => {
          setTimeout(resolve, delay);
        }).then(() => {
          DashboardController.getCorporateDashboardDeliveryData({
            path: IS_SENDER_ACCOUNT
              ? "/delivery/status"
              : "/delivery/total/status",
            sttCategory: delivery.type,
            cache: !IS_SENDER_ACCOUNT
          }).then((resp: DataCorporateDashboard) => {
            delivery.loading = false;
            setTimeout(() => {
              delivery.value = resp.totalStt;
            }, 100);
          });
        });
      }
    );
    await Promise.all(requestCorporateDashboardDelivery);
  }

  // status delivery
  get ableToViewDetail() {
    if (IS_SENDER_ACCOUNT) return true;
    return checkRolePermission(TRACK_DELIVERY.ENABLE);
  }
  totalStatusDelivery = {
    title: "Total Resi",
    tooltip: "Total STT yang berhasil terbooking",
    type: "TOTAL",
    value: 0,
    loading: true
  };
  valueProgressBar(val: number, maxValue: number) {
    return (val / maxValue) * 100;
  }
  deliveryPerStatus: Array<any> = [
    {
      title: "Dalam Proses",
      tooltip: "Total STT yang sedang dalam proses pengiriman",
      type: "IN_PROGRESS",
      value: 0,
      loading: true,
      color: "#FAB713",
      ableToClick: this.ableToViewDetail
    },
    {
      title: "Telah Diterima",
      tooltip: "Total STT yang berhasil diterima",
      type: "SUCCESS",
      value: 0,
      loading: true,
      color: "#36B37E",
      ableToClick: this.ableToViewDetail
    },
    {
      title: "Gagal Kirim",
      tooltip: "Total STT yang gagal dikirim",
      type: "FAILED",
      value: 0,
      loading: true,
      color: "#B82025",
      ableToClick: this.ableToViewDetail
    },
    {
      title: "Dikembalikan",
      tooltip: "Total STT yang dikembalikan",
      type: "RETURN",
      value: 0,
      loading: true,
      color: "#ED6240",
      ableToClick: this.ableToViewDetail
    }
  ];

  onClickDetail(dashboardInfo: string) {
    if (!this.ableToViewDetail) return;
    DashboardController.openNewPageFromDashboardCorporate(dashboardInfo);
  }
}
