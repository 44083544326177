
import { Vue, Options } from "vue-class-component";
import CardDashboardClient from "@/app/ui/views/dashboard/component/client/component/card-dashboard-client.vue";
import BulletPoint from "@/app/ui/components/icons/modules/bullet-point.vue";
import ColumnChart from "@/app/ui/components/chart/column-bar/index.vue";
import { DashboardController } from "@/app/ui/controllers/DashboardController";

@Options({
  components: {
    BulletPoint,
    CardDashboardClient,
    ColumnChart
  }
})
export default class DeliveryDetailCard extends Vue {
  async mounted() {
    await this.fetchData();
  }

  async fetchData() {
    this.chart.loading = true;
    const response = await DashboardController.getCorporateDashboardDetailAreaBarChart(
      {
        path: "/delivery/detail"
      }
    );
    this.chart.loading = false;
    this.chart.series = response.totalResi.series;
    this.chart.categories = response.totalResi.categories;
  }
  chart: any = {
    loading: false,
    title: "Total Resi",
    series: [],
    categories: []
  };
}
