
import { Vue, Options } from "vue-class-component";
import CardDashboardClient from "@/app/ui/views/dashboard/component/client/component/card-dashboard-client.vue";
import { formatPriceWithoutCurrency } from "@/app/infrastructures/misc/Utils";
import { DashboardController } from "@/app/ui/controllers/DashboardController";
import { DeliveryPerRouteData } from "@/domain/entities/Dashboard";

@Options({
  components: {
    CardDashboardClient
  }
})
export default class DeliveryPerRouteCard extends Vue {
  mounted() {
    this.getDashboardDeliveryPerRouteData();
  }
  formattedNumber(value: number) {
    return formatPriceWithoutCurrency(value);
  }
  isLoading = true;

  async getDashboardDeliveryPerRouteData() {
    this.isLoading = true;
    const response = await DashboardController.getCorporateDashboardDeliveryData(
      {
        path: "/delivery/route",
        sttCategory: "",
        cache: true
      }
    );
    this.isLoading = false;
    this.dataPerRouteTotalResi = response.deliveryPerRouteData;
  }

  // per route
  sortingPerRouteTotalResi = { sortBy: "desc", orderBy: "route" };
  get isDescending() {
    return this.sortingPerRouteTotalResi.sortBy === "desc";
  }
  iconSorting(key: string) {
    return this.disableIconHeader(key) ||
      (this.isDescending && this.sortingPerRouteTotalResi.orderBy === key)
      ? "descending"
      : "ascending";
  }
  disableIconHeader(key: string) {
    return this.sortingPerRouteTotalResi.orderBy !== key;
  }
  onSorting(column: any) {
    this.sortingPerRouteTotalResi = {
      sortBy:
        this.isDescending &&
        this.sortingPerRouteTotalResi.orderBy === column?.sortingKey
          ? "asc"
          : "desc",
      orderBy: column?.sortingKey
    };
  }
  get columns() {
    return [
      {
        name: "Rute",
        styleHead: "w-28 text-white text-left whitespace-no-wrap align-top",
        columnKey: "route",
        sortingKey: "route",
        icon: this.iconSorting("route"),
        vtooltipHeader: () => null,
        disableIconHeader: (column: any) =>
          this.disableIconHeader(column?.sortingKey),
        onClickHeader: this.onSorting,
        render: (item: any) => {
          return item.routeName;
        }
      },
      {
        name: "Total Resi",
        styleHead: "w-28 text-white text-left whitespace-no-wrap align-top",
        columnKey: "total_resi",
        sortingKey: "total_resi",
        icon: this.iconSorting("total_resi"),
        vtooltipHeader: () => null,
        disableIconHeader: (column: any) =>
          this.disableIconHeader(column?.sortingKey),
        onClickHeader: this.onSorting,
        render: (item: any) => {
          return `${this.formattedNumber(item.totalStt)}`;
        }
      }
    ];
  }
  dataPerRouteTotalResi: DeliveryPerRouteData[] = [];
  sortDataPerRouteByRoute(a: DeliveryPerRouteData, b: DeliveryPerRouteData) {
    if (this.sortingPerRouteTotalResi.sortBy === "asc") {
      if (a.routeName < b.routeName) {
        return -1;
      }
      if (a.routeName > b.routeName) {
        return 1;
      }
      return 0;
    }
    if (this.sortingPerRouteTotalResi.sortBy === "desc") {
      if (b.routeName < a.routeName) {
        return -1;
      }
      if (b.routeName > a.routeName) {
        return 1;
      }
      return 0;
    }
    return 0;
  }
  sortDataPerRouteByTotalResi(
    a: DeliveryPerRouteData,
    b: DeliveryPerRouteData
  ) {
    if (this.sortingPerRouteTotalResi.sortBy === "asc") {
      return a.totalStt - b.totalStt;
    }
    if (this.sortingPerRouteTotalResi.sortBy === "desc") {
      return b.totalStt - a.totalStt;
    }
    return 0;
  }
  get sortedDataPerRouteTotalResi() {
    return this.dataPerRouteTotalResi.sort(
      (a: DeliveryPerRouteData, b: DeliveryPerRouteData) => {
        if (this.sortingPerRouteTotalResi.orderBy === "route") {
          return this.sortDataPerRouteByRoute(a, b);
        }
        if (this.sortingPerRouteTotalResi.orderBy === "total_resi") {
          return this.sortDataPerRouteByTotalResi(a, b);
        }
        return 0;
      }
    );
  }
  perRouteDetail(item: DeliveryPerRouteData) {
    let templateHtml =
      `<div class="w-40 text-10px">` +
      `<div class="text-center"><strong>${item.routeName}</strong></div>` +
      `<hr class="my-2" />` +
      `<div class="grid gap-y-1 grid-cols-2 mb-1">`;

    for (const routeProduct in item.sttByProductType) {
      templateHtml += `<div><div class="text-8px text-gray-lp-800 capitalize">${item.sttByProductType[routeProduct].productType}</div><strong>${item.sttByProductType[routeProduct].totalStt} Resi</strong></div>`;
    }

    templateHtml += `</div>` + `</div>`;
    return templateHtml;
  }
}
