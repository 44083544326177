import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex flex-col justify-between h-full pb-4" }
const _hoisted_2 = { class: "my-4 flex justify-center" }
const _hoisted_3 = { class: "grid grid-cols-3 gap-4" }
const _hoisted_4 = { class: "flex items-center text-black-lp-300" }
const _hoisted_5 = { class: "text-10px text-gray-lp-800 font-medium capitalize" }
const _hoisted_6 = { class: "mt-1.5 text-20px font-semibold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DonutChart = _resolveComponent("DonutChart")!
  const _component_bullet_point = _resolveComponent("bullet-point")!
  const _component_CardDashboardClient = _resolveComponent("CardDashboardClient")!

  return (_openBlock(), _createBlock(_component_CardDashboardClient, {
    loading: _ctx.isLoading,
    title: "Distribusi Per Produk"
  }, {
    default: _withCtx(() => [
      _createVNode("div", _hoisted_1, [
        _createVNode("div", _hoisted_2, [
          _createVNode(_component_DonutChart, {
            chartSeries: _ctx.chartData,
            chartColor: _ctx.chartColor,
            isShowTotal: "",
            chartTitle: "Pengiriman"
          }, null, 8, ["chartSeries", "chartColor"])
        ]),
        _createVNode("div", _hoisted_3, [
          (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.productDistribution, (perProduct, key) => {
            return (_openBlock(), _createBlock("div", { key: key }, [
              _createVNode("div", _hoisted_4, [
                _createVNode(_component_bullet_point, {
                  style: `color: ${perProduct.color}`,
                  class: "w-2 mr-1"
                }, null, 8, ["style"]),
                _createVNode("div", _hoisted_5, _toDisplayString(perProduct.name), 1)
              ]),
              _createVNode("div", _hoisted_6, _toDisplayString(_ctx.formattedNumber(perProduct.value)) + " Resi ", 1)
            ]))
          }), 128))
        ])
      ])
    ]),
    _: 1
  }, 8, ["loading"]))
}