
import { Vue, Options } from "vue-class-component";
import DeliveryStatusCard from "@/app/ui/views/dashboard/component/client/section-delivery/delivery-status-card.vue";
import DeliveryDetailCard from "@/app/ui/views/dashboard/component/client/section-delivery/delivery-detail-card.vue";
import DeliveryDistributionPerProductCard from "@/app/ui/views/dashboard/component/client/section-delivery/delivery-distribution-per-product-card.vue";
import DeliveryPerRouteCard from "@/app/ui/views/dashboard/component/client/section-delivery/delivery-per-route-card.vue";
import { IS_SENDER_ACCOUNT } from "@/app/infrastructures/misc/Constants";
import { AccountController } from "@/app/ui/controllers/AccountController";
@Options({
  components: {
    DeliveryPerRouteCard,
    DeliveryDistributionPerProductCard,
    DeliveryDetailCard,
    DeliveryStatusCard
  }
})
export default class SectionDeliveryDashboard extends Vue {
  get isSenderAccount() {
    return IS_SENDER_ACCOUNT;
  }
  get title() {
    if (AccountController.accountData.isPosAccount) {
      return "Informasi Paket";
    }
    return "Pengiriman";
  }
}
